
import Vue from 'vue'
import { name as appName } from '@/../package.json'

export default Vue.extend({
  name: 'SelectLayoutMenu',
  props: {
    camerasLayoutsInMenu: Array as () => CamerasLayout[],
  },
  data() {
    return {
      appName,
    }
  },
  methods: {
    menuToggle(ref: string, event: Event) {
      let menu = this.$refs[ref] as Vue & {
        toggle: (event: Event) => {}
      }
      menu = Array.isArray(menu) ? menu[0] : menu
      if (menu !== undefined) {
        menu!.toggle(event)
      }
    },
    select(layout: CamerasLayout) {
      this.$emit('selectLayout', layout)
    },
  },
})
