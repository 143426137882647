
import { name as appName } from '@/../package.json'
import { Draggable } from '@shopify/draggable'
import { cloneDeep } from 'lodash'
import Mixins from './common/Mixins'
import { mapGettersTyped, mapStateTyped } from '@/store'

export default Mixins.extend({
  name: 'AddCameraSidebar',
  props: {
    expanded: Boolean,
    camerasLayout: Object as () => CamerasLayout,
  },
  data() {
    return {
      loading: true,
      appName,
      infiniteScroll: true,
      cameras: [] as Camera[],
      unmount: {} as any,
      searchCamera: '',
      inputTimer: {} as any,
      overGrid: {} as any,
      limit: 5,
      offset: 0,
      count: 0,
      draggable: { destroy() {} } as any,
      orderByNameAsc: true,
      queryConfig: {} as any,
    }
  },
  watch: {
    expanded(val) {
      if (val) {
        this.initCameras()
      }
    },
    orderByNameAsc() {
      if (this.cameras.length === this.count) {
        this.cameras = this.cameras.sort(({ title: titleA }, { title: titleB }) => {
          if (titleA < titleB) {
            return this.orderByNameAsc ? -1 : 1
          }
          if (titleA > titleB) {
            return this.orderByNameAsc ? 1 : -1
          }
          return 0
        })
      } else {
        this.initCameras()
      }
    },
    searchCamera(val: string) {
      const clearedVal = val.replace(/[^a-zA-Z0-9а-яА-Я-.,\s]/g, '')
      if (this.searchCamera.length === clearedVal.length) {
        this.count = 0
        this.requestDelay(true, () => {
          this.infiniteScroll = true
          this.cameras = []
          this.offset = 0
          this.fetchCameras()
        })
      } else {
        this.searchCamera = clearedVal
      }
    },
  },
  computed: {
    ...mapStateTyped(['widgetProps']),
    ...mapGettersTyped(['api']),
    camerasIdsInLayout(): string[] {
      return this.camerasLayout !== null
        ? (this.camerasLayout.cameras.filter((e: string | null) => e !== null) as string[])
        : []
    },
    sidebarStyle() {
      return this.expanded
        ? {
            width: '390px',
            marginRight: '20px',
          }
        : { width: '0px', marginRight: '0px', padding: '0px' }
    },
  },
  methods: {
    initCameras() {
      this.infiniteScroll = true
      this.cameras = []
      this.offset = 0
      this.fetchCameras()
    },
    onScroll({ target }: Event) {
      const tagetHTML = target as HTMLElement
      if (
        this.infiniteScroll &&
        !this.loading &&
        tagetHTML &&
        tagetHTML.scrollTop + tagetHTML.clientHeight >= tagetHTML.scrollHeight - 10
      ) {
        this.fetchCameras()
      }
    },
    initDrag() {
      this.draggable.destroy()
      this.draggable = new Draggable(document.querySelectorAll('.card-view__content'), {
        draggable: '.draggable',
        handle: '.drag-handle',
        delay: 16,
        distance: 2,
      })
      this.draggable.on('drag:start', () => {
        this.overGrid = { id: null }
        this.infiniteScroll = false
      })
      this.draggable.on('drag:over', (event: any) => {
        if (event.data.over.classList.contains('camera-wrapper')) {
          this.overGrid = { ...event.data.over.dataset }
        } else {
          this.overGrid = { id: null }
        }
      })
      this.draggable.on('drag:out', () => {
        this.overGrid = { id: null }
      })
      this.draggable.on('drag:stop', (event: any) => {
        this.infiniteScroll = true
        if (this.overGrid.id !== null) {
          const targetCamera = this.cameras.find(
            ({ id }) => id === event.data.originalSource.dataset.id,
          )
          this.$emit('addCamera', {
            index: Number(this.overGrid.id),
            camera: targetCamera,
          })
        }
      })
    },
    async fetchCameras(): Promise<void> {
      if (!this.api.cubeGetEntities) {
        throw new Error("cubeGetEntities method doesn't exist in api")
      }
      if (!this.widgetProps?.cubeListQuery) {
        throw new Error("cubeListQuery property doesn't provide in spa props")
      }

      if (!this.infiniteScroll) {
        return Promise.resolve()
      }

      this.loading = true
      const queryConfig = cloneDeep(this.widgetProps.cubeListQuery)
      this.queryConfig = queryConfig
      if (this.searchCamera.length) {
        queryConfig.filters.push({
          member: 'Objects.title',
          operator: 'contains',
          values: [
            this.searchCamera,
            this.searchCamera.toUpperCase(),
            this.searchCamera.toLowerCase(),
            this.searchCamera[0].toUpperCase() + this.searchCamera.substring(1).toLowerCase(),
          ],
        })
      }

      try {
        const { results } = await this.api.cubeGetEntities({
          ...queryConfig,
          offset: this.offset,
          limit: this.limit,
          order: {
            'Objects.title': this.orderByNameAsc ? 'asc' : 'desc',
          },
        })
        if (results.length === 0) {
          this.infiniteScroll = false
          return
        }
        if (queryConfig !== this.queryConfig) {
          return
        }
        this.cameras = [...this.cameras, ...results]
        if (this.cameras.length === this.count) {
          this.infiniteScroll = false
        }
        this.offset += this.limit
        this.initDrag()
        if (this.count === 0) {
          this.count = await this.api.cubeGetQuantity({
            filters: queryConfig.filters,
            measures: ['Objects.count'],
          })
        }
      } catch (error) {
        this.errorToast(error)
      } finally {
        this.loading = false
      }
    },
  },
})
