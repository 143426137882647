var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"layouts-main",attrs:{"id":`${_vm.appName}:layouts-main`}},[_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"layouts-tabs__bar"},[(_vm.mainLoading && _vm.camerasLayouts.length === 0)?_c('div',{staticClass:"layouts-tabs__button layouts-tabs__button_active",staticStyle:{"border":"none"}},[_c('span',{staticClass:"layouts-tabs__button__label",domProps:{"textContent":_vm._s(_vm.$t('layouts'))}})]):_vm._e(),(_vm.count === 0 && !_vm.mainLoading)?_c('div',{staticClass:"layouts-tabs__button layouts-tabs__button_active",staticStyle:{"border":"none"}},[_c('span',{staticClass:"layouts-tabs__button__label",attrs:{"data-cy":"title-page"},domProps:{"textContent":_vm._s(_vm.$t('message.createCamerasLayout'))}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.camerasLayouts.length !== 0),expression:"camerasLayouts.length !== 0"}],ref:"fullButtonsBar",staticClass:"layouts-tabs__bar-wrapper p-mr-3"},[_c('div',{ref:"currentButtonsBar",staticClass:"layout-tabs__resize-observer"},[_c('transition-group',{attrs:{"name":"list-complete","tag":"div"}},_vm._l((_vm.camerasLayoutsInTabs),function(camerasLayout){return _c('div',{key:camerasLayout.id,staticClass:"layouts-tabs__button list-complete-item",class:{
              'layouts-tabs__button_active':
                camerasLayout.id === _vm.currentCamerasLayoutId && !_vm.mainLoading,
            },on:{"click":function($event){_vm.editMode
                ? () => {
                    _vm.editMode = false
                    _vm.openLayout(camerasLayout)
                  }
                : _vm.openLayout(camerasLayout)}}},[_c('span',{staticClass:"layouts-tabs__button__label",attrs:{"data-cy":"title-layout","role":"text","title":camerasLayout.name || ''},domProps:{"textContent":_vm._s(camerasLayout.name || _vm.$t('layout'))}}),((_vm.editMode || _vm.isNewLayout) && _vm.currentCamerasLayoutId === camerasLayout.id)?_c('Rename',{staticClass:"p-ml-1",attrs:{"data-cy":"button-rename","entity":camerasLayout},on:{"resize":_vm.splitLayouts}}):_vm._e()],1)}),0)],1)]),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.left",value:(_vm.$t('button.addLayout')),expression:"$t('button.addLayout')",modifiers:{"left":true}}],staticClass:"p-button-outlined p-button-secondary p-button-sm",attrs:{"disabled":_vm.mainLoading,"icon":"mdi mdi-18px mdi-plus","data-cy":"button-add-layout"},on:{"click":_vm.createEmptyCamerasLayout}}),(_vm.camerasLayoutsInMenu.length > 0)?_c('SelectLayoutMenu',{staticClass:"p-ml-2",attrs:{"camerasLayoutsInMenu":_vm.camerasLayoutsInMenu},on:{"selectLayout":_vm.selectMenuLayout}}):_vm._e(),_c('ProgressBar',{staticClass:"progress-bar",style:({
        opacity: _vm.mainLoading ? 1 : 0,
      }),attrs:{"mode":"indeterminate"}})],1),_c('div',{staticClass:"layouts__container"},[(_vm.count === 0 && !_vm.mainLoading)?_c('EmptyLayouts',{on:{"createCallback":_vm.createEmptyCamerasLayout}}):_vm._e(),(_vm.currentCamerasLayout !== null && _vm.currentCamerasLayout.cameras.length > 0)?_c('CameraGrid',{attrs:{"editMode":_vm.editMode,"camerasLayout":_vm.currentCamerasLayout},on:{"update:editMode":function($event){_vm.editMode=$event},"update:edit-mode":function($event){_vm.editMode=$event},"update:camerasLayout":function($event){_vm.currentCamerasLayout=$event},"update:cameras-layout":function($event){_vm.currentCamerasLayout=$event},"closeGrid":function($event){return _vm.fetchEntities()}}}):_vm._e(),(_vm.isNewLayout)?_c('CreateLayout',{attrs:{"camerasLayout":_vm.currentCamerasLayout},on:{"update:camerasLayout":function($event){_vm.currentCamerasLayout=$event},"update:cameras-layout":function($event){_vm.currentCamerasLayout=$event},"closeGrid":function($event){return _vm.fetchEntities()}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }