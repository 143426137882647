export const insensitify = (str: string) =>
  str
    .split('')
    .map((char) => {
      if (char.match(/[a-zа-я]/i)) {
        return `[${char.toLowerCase()}${char.toUpperCase()}]`
      } else {
        return char
      }
    })
    .join('')

const timers = {} as any
export const debounce = (callback: Function, timeout: number, key: string = 'main') => {
  clearTimeout(timers[key])
  timers[key] = setTimeout(callback, timeout)
}

export const capitalize = (str: string) => {
  return typeof str === 'string' && str !== ''
    ? str.replace(/^\w/, (c) => c.toLocaleUpperCase())
    : ''
}
