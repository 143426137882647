
import Vue from 'vue'
import { repositoryGetter } from '@netvision/lib-api-repo'
import LayoutsMain from './components/LayoutsMain.vue'
import { name as appName } from '@/../package.json'
import { get as getFromIDB } from 'idb-keyval'
import { mapMutationsTyped, mapStateTyped } from './store'

export default Vue.extend({
  name: 'App',
  components: {
    LayoutsMain,
  },
  provide(): any {
    return {
      spaParent: this.$parent,
    }
  },
  computed: {
    ...mapStateTyped(['LAYOUT_TYPES']),
  },
  async beforeMount() {
    this.reset()
    const api = repositoryGetter(this.$parent?.props?.lib)
    this.setValue(['widgetProps', this.$parent?.props || {}])
    this.setValue(['api', api])
    if ('getUserInfo' in api) {
      try {
        const res = await api.getUserInfo()
        if (res && 'userId' in res) {
          const { userId } = res
          this.setValue(['userId', userId || null])
          const layoutStore = await getFromIDB(`${appName}${userId}-layouts`)
          layoutStore && this.setValue(['layoutStore', layoutStore])
        }
      } catch (error) {
        console.error(error)
      }
    }
    const allowedLayouts = this.$parent?.props?.allowedLayouts
    if (Array.isArray(allowedLayouts)) {
      const layoutTypes = allowedLayouts.reduce((acc, val) => {
        const layoutType = this.LAYOUT_TYPES[val]
        layoutType && (acc[val] = layoutType)
        return acc
      }, {} as Record<string, unknown>)
      Object.keys(layoutTypes).length && this.setValue(['LAYOUT_TYPES', layoutTypes])
    }
  },
  methods: {
    ...mapMutationsTyped(['setValue', 'reset']),
  },
})
