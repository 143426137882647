
import Mixins from '@/components/common/Mixins'
import { mapState, mapGetters } from 'vuex'
const strictRegEx = /[^a-zA-Z0-9а-яА-Я-.,\s]/g

export default Mixins.extend({
  name: 'Rename',
  props: {
    entity: Object as () => CamerasLayout,
  },
  data() {
    return {
      visible: false,
      currentName: this.entity.name || '',
    }
  },
  computed: {
    ...mapState(['MAX_NAME_LENGTH']),
    ...mapGetters(['api']),
  },
  watch: {
    visible(val) {
      if (val) {
        setTimeout(() => {
          const input = document.getElementById(`input-${this._uid}`) as HTMLInputElement
          if (input) {
            input.focus()
          }
        })
      }
    },
    currentName: {
      handler(val) {
        this.currentName = val.replace(strictRegEx, '').slice(0, this.MAX_NAME_LENGTH)
      },
      immediate: true
    },
  },
  methods: {
    async changeName() {
      this.currentName = this.currentName.trim()
      const options = {
        type: this.entity.type,
        id: this.entity.id,
        name: this.currentName,
      }
      try {
        await (this.entity.name === undefined
          ? this.api.appendEntity(options)
          : this.api.updateEntity(options))
        this.visible = false
        this.entity.name = this.currentName
        this.$emit('resize')
      } catch (error) {
        this.errorToast(error)
      }
    },
  },
})
