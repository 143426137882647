import './set-public-path'
import Vue from 'vue'
import Vuex from 'vuex'
import singleSpaVue from 'single-spa-vue'
import VueI18n from 'vue-i18n'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import Store from './store'
// @ts-ignore
import VueResizeObserver from 'vue-resize-observer'

import ProgressSpinner from 'primevue/progressspinner'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import SplitButton from 'primevue/splitbutton'
import Paginator from 'primevue/paginator'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import Menu from 'primevue/menu'
import OverlayPanel from 'primevue/overlaypanel'
import Tooltip from 'primevue/tooltip'
import AutoComplete from 'primevue/autocomplete'
import SelectButton from 'primevue/selectbutton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ScrollPanel from 'primevue/scrollpanel'
import DeleteDialog from './components/common/DeleteDialog.vue'
import CameraCard from './components/common/CameraCard.vue'
import Checkbox from 'primevue/checkbox'
import ProgressBar from 'primevue/progressbar'
import MultiSelect from 'primevue/multiselect'
import App from './App.vue'

Vue.use(VueResizeObserver)
Vue.use(ToastService)
Vue.use(VueI18n)
Vue.use(Vuex)

Vue.component('Textarea', Textarea)
Vue.component('InputText', InputText)
Vue.component('SplitButton', SplitButton)
Vue.component('Paginator', Paginator)
Vue.component('Toast', Toast)
Vue.component('Dropdown', Dropdown)
Vue.component('Menu', Menu)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('AutoComplete', AutoComplete)
Vue.component('SelectButton', SelectButton)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('ScrollPanel', ScrollPanel)
Vue.component('DeleteDialog', DeleteDialog)
Vue.component('CameraCard', CameraCard)
Vue.component('Checkbox', Checkbox)
Vue.component('ProgressBar', ProgressBar)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('Dialog', Dialog)
Vue.component('Button', Button)
Vue.component('MultiSelect', MultiSelect)
Vue.directive('tooltip', Tooltip)
Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue()

const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
}
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
})

const store = new Vuex.Store(Store)
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    directives: {
      tooltip: Tooltip,
    },
    i18n,
    store,
    render: (h: any) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
export const update = vueLifecycles.update
