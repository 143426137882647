
import CardsView from './common/CardsView.vue'
import AddCameraSidebar from './AddCameraSidebar.vue'
import CameraCard from './common/CameraCard.vue'
import LayoutStyleSelectSet from './common/LayoutStyleSelectSet.vue'
import Mixins from './common/Mixins'
import { Sortable, Plugins } from '@shopify/draggable'
import { FullscreenService } from '@/FullscreenService'
import { debounce } from '@/utils'
import SelectStreamType from './common/SelectStreamType.vue'
import { mapGettersTyped, mapMutationsTyped, mapStateTyped } from '@/store'
import AdditionalCommands from './common/AdditionalCommands.vue'

export default Mixins.extend({
  name: 'CameraGrid',
  components: {
    CardsView,
    AddCameraSidebar,
    CameraCard,
    LayoutStyleSelectSet,
    SelectStreamType,
    AdditionalCommands,
  },
  inject: ['spaParent'],
  props: {
    camerasLayout: Object as () => CamerasLayout,
    editMode: Boolean,
  },
  data() {
    return {
      unmount: {} as any,
      findedCameras: [] as any,
      displayDeleteCamera: false,
      displayDeleteLayout: false,
      deleteCameraIndex: 0,
      deleteCameraId: null as string | null,
      searchCameraName: [] as string[],
      sortable: { destroy() {} } as any,
      currentLayoutType: this.camerasLayout.layoutType as LayoutType,
      isFullscreen: false,
      mouseMovedRecently: false,
      fullscreenService: new FullscreenService(),
    }
  },
  computed: {
    ...mapStateTyped(['LAYOUT_TYPES', 'widgetProps', 'mainLoading', 'layoutStore']),
    ...mapGettersTyped(['api', 'layouts']),
    layoutStreamTypes(): LayoutStore[string] {
      return this.layoutStore?.[this.camerasLayout.id] || {}
    },
    isAdditionalCommandsProvided(): boolean {
      // @ts-ignore
      return this.spaParent.areas.some((e: any) => e.name === 'additionalCommands')
    },
    layoutStylesOptions(): object[] {
      return Object.entries(this.LAYOUT_TYPES).map(([key, value]: [string, any]) => {
        return { value: key, ...value }
      })
    },
  },
  watch: {
    currentLayoutType(value: LayoutType) {
      const newCount = this.LAYOUT_TYPES[value]?.count
      const oldCount = this.camerasLayout.cameras.length
      const cameras = this.camerasLayout.cameras.filter((e) => e !== '')
      if (newCount && newCount !== oldCount && oldCount !== 0) {
        const options = {
          type: 'CamerasLayout',
          id: this.camerasLayout.id,
          layoutType: value,
          cameras:
            newCount > oldCount
              ? [...this.camerasLayout.cameras, ...new Array(newCount - oldCount).fill('')]
              : [...cameras, ...new Array(newCount - cameras.length).fill('')],
        }
        this.api
          .updateEntity(options)
          .then(() => {
            this.$emit('update:camerasLayout', {
              ...this.camerasLayout,
              ...options,
            })
          })
          .catch(this.errorToast)
      }
    },
    camerasLayout: {
      async handler(val) {
        if (val !== null) {
          this.currentLayoutType = val.layoutType
          setTimeout(() => {
            this.initSortable()
          })
          if (!this.layouts[val.id]) {
            this.fetchCameras()
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutationsTyped(['setCameraStream']),
    async fetchCameras() {
      this.$store.commit('setValue', ['mainLoading', true])
      try {
        const { results } = await this.api.batchQuery({
          filter: {
            entities: this.camerasLayout.cameras.reduce<Omit<IEntity, 'title'>[]>((acc, next) => {
              if (next?.length) {
                acc.push({ id: next, type: 'Camera' })
              }
              return acc
            }, []),
          },
          limiter: {},
        })
        this.$store.commit('setLayout', [
          this.camerasLayout.id,
          {
            cameras: this.camerasLayout.cameras.reduce<Camera[]>((acc, next, index) => {
              const targetCamera = results.find(({ id }: Camera) => id === next)
              if (targetCamera) {
                acc.push({
                  ...targetCamera,
                  indexInLayout: index,
                  addressLocality: targetCamera.addressLocality,
                  streetAddress: targetCamera.streetAddress,
                  iconClass: targetCamera.iconClass,
                  iconColor: targetCamera.iconColor,
                })
              }
              return acc
            }, []),
          },
        ])
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.commit('setValue', ['mainLoading', false])
      }
    },
    initSortable() {
      this.sortable.destroy()
      this.sortable = new Sortable(document.querySelectorAll('.layout'), {
        draggable: '.sortable',
        handle: '.sortable-handle',
        mirror: {
          constrainDimensions: true,
        },
        plugins: [Plugins.SortAnimation],
        sortAnimation: {
          duration: 200,
          easingFunction: 'ease-in-out',
        },
      })
      this.sortable.on('sortable:stop', (event: any) => {
        if (event.oldIndex !== event.newIndex) {
          this.replaceCameras(event.oldIndex, event.newIndex)
        }
      })
    },
    clickBackButton() {
      this.fullscreenService.exitFullscreen()
    },
    openFullScreen() {
      const el = document.getElementById('fullScreenAnchor')
      if (el) {
        this.fullscreenService.requestFullscreen(el)
      }
    },
    clickAll(selector: string) {
      const button = document.querySelectorAll(selector) as NodeListOf<HTMLElement>
      if (button) {
        Array.from(button).forEach((e: HTMLElement) => {
          e.click()
        })
      }
    },
    async updateCamerasInLayout(cameras: (string | null)[]) {
      try {
        const options = {
          type: 'CamerasLayout',
          id: this.camerasLayout.id,
          cameras,
        }
        await this.api.updateEntity(options)
        this.$emit('update:camerasLayout', {
          ...this.camerasLayout,
          cameras,
        })
      } catch (error) {
        console.error(error)
      }
    },
    replaceCameras(oldIndex: number, newIndex: number) {
      const cameras = [...this.camerasLayout.cameras]
      const camera = cameras[oldIndex]
      cameras.splice(oldIndex, 1)
      cameras.splice(newIndex, 0, camera)
      this.updateCamerasInLayout(cameras)
    },
    addCamera({ index, camera }: { index: number; camera?: Camera }) {
      if (!camera) {
        return this.errorToast({
          message: this.$t('errorCameraAdding'),
        })
      }
      const cameras = [...this.camerasLayout.cameras]
      cameras[index] = camera.id
      this.camerasLayout.cameras = cameras
      this.$store.commit('setLayout', [
        this.camerasLayout.id,
        {
          cameras: [
            ...this.layouts[this.camerasLayout.id].cameras,
            { ...camera, indexInLayout: index },
          ],
        },
      ])
      this.updateCamerasInLayout(cameras)
    },
    deleteCamera() {
      const cameras = this.camerasLayout.cameras.map((camera, index) =>
        camera === this.deleteCameraId && index === this.deleteCameraIndex ? '' : camera,
      )
      this.camerasLayout.cameras = cameras
      this.$store.commit('setLayout', [
        this.camerasLayout.id,
        {
          cameras: this.layouts[this.camerasLayout.id].cameras.filter(
            ({ indexInLayout }: Camera) => indexInLayout !== this.deleteCameraIndex,
          ),
        },
      ])
      this.deleteCameraId = null
      this.deleteCameraIndex = 0
      this.updateCamerasInLayout(cameras)
    },
    async deleteLayout() {
      try {
        await this.api.deleteEntity({
          type: 'CamerasLayout',
          id: this.camerasLayout.id,
        })
        this.$emit('closeGrid')
        this.displayDeleteLayout = false
      } catch (error) {
        this.errorToast(error)
      }
    },
    setButtonVisible() {
      this.mouseMovedRecently = true
      debounce(() => {
        this.mouseMovedRecently = false
      }, 5000)
    },
    setLayoutStream(streamType?: string) {},
  },
  created() {
    if (!(this.camerasLayout.id in this.layouts)) {
      this.$store.commit('setLayout', [this.camerasLayout.id])
    }
  },
  mounted() {
    this.initSortable()
    this.isFullscreen = this.fullscreenService.isFullscreen()
    this.fullscreenService.subscribe((value) => {
      this.isFullscreen = value
    })
    if (!this.layouts[this.camerasLayout.id].cameras.length || this.mainLoading) {
      this.fetchCameras()
    }
  },
  destroyed() {
    this.fullscreenService.destroy()
  },
})
