export const RUSSIAN_TRANSLATIONS = {
  button: {
    ok: 'ОК',
    yes: 'Да',
    no: 'Нет',
    delete: 'Удалить',
    save: 'Сохранить',
    create: 'Создать',
    cancel: 'Отмена',
    done: 'Готово',
    close: 'Закрыть',
    edit: 'Настроить',
    deleteLayout: 'Удалить',
    unselect: 'Отменить выделение',
    selectAll: 'Выбрать все',
    createNewCamerasLayout: 'Создайте новую раскладку',
    createCamerasLayout: 'Создать',
    moreLayouts: 'Еще раскладки',
    mainCamera: 'Основная камера',
    addLayout: 'Добавить раскладку',
  },
  message: {
    deleteTitle: 'Удалить',
    deleteCamera: 'Вы уверены, что хотите убрать камеру из раскладки?',
    deleteLayout: 'Вы уверены, что хотите удалить раскладку?',
    deleteEntity: 'Удалить без возможности восстановления?',
    cameraNotChosen: 'Камера не выбрана',
    createCamerasLayout: 'Создать раскладку',
  },
  toggleQuality: 'Переключить качество видео',
  name: 'Название',
  layout: 'Раскладка',
  layouts: 'Раскладки',
  unknownRef: 'Без названия',
  loading: 'Загрузка...',
  selectObject: 'Выбрать объект',
  hideCameras: 'Спрятать меню камер',
  description: 'Описание',
  emptyCameras: 'Камеры не найдены',
  changeYourRequest: 'Измените ваш запрос',
  emptyDescription: 'Описание отсутствует',
  fullScreen: 'Во\xa0весь экран',
  pauseAll: 'Остановить все',
  startAll: 'Запустить все',
  search: 'Поиск',
  emptyLabel: 'У вас нет ни одной раскладки камер',
  select: 'Выбрать',
  findCamera: 'Добавить камеру',
  setLayoutName: 'Название',
  cameras: 'Камеры',
  selectedCameras: 'Выбрано камер',
  error: 'Ошибка',
  errorCameraAdding: 'Перезагрузите страницу и попробуйте еще раз',
  orderByName: 'Упорядочить по имени',
  menuLabels: {
    cut: 'ВЫРЕЗАТЬ',
    delete: 'УДАЛИТЬ',
    share: 'ДОСТУП',
    paste: 'ВСТАВИТЬ',
    download: 'СКАЧАТЬ',
    toRoot: 'В КОРЕНЬ',
    open: 'ОТКРЫТЬ',
  },
  dialog: {
    rename: 'Переименовать',
  },
  nothingFound: 'Ничего не найдено',
  deleteEntity: 'Удалить объект',
  createEntity: 'Создать объект',
  editEntity: 'Редактировать объект',
  all: 'Все',
  dragAndDrop: 'Зацепите мышкой и перетащите',
  finded: 'Найдено совпадений',
}
