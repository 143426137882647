import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'Mixins',
  data() {
    return {
      inputTimer: {} as ReturnType<typeof setTimeout>,
    }
  },
  computed: {
    ...mapState(['permissionScopes']),
  },
  methods: {
    canI(scope: string, id: string | null = null): Boolean {
      const scopes = this.permissionScopes.get(id)
      return Array.isArray(scopes) ? scopes.includes(scope) : false
    },
    errorToast(error: any) {
      console.error(error)
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error'),
        detail: error.message,
        life: 5000,
      })
    },
    insensitify(str: string) {
      return str
        .split('')
        .map((char) => {
          if (char.match(/[a-zа-я]/i)) {
            return `[${char.toLowerCase()}${char.toUpperCase()}]`
          } else {
            return char
          }
        })
        .join('')
    },
    requestDelay(condition: boolean, callback: Function) {
      if (condition) {
        clearTimeout(this.$data.inputTimer)
        this.$data.inputTimer = setTimeout(callback, 1000)
      } else {
        clearTimeout(this.$data.inputTimer)
      }
    },
  },
})
