export type IFullscreenCallback = (isFullscreen: boolean) => void

export class FullscreenService {
  private clients = new Set<IFullscreenCallback>()
  private listeners: Array<[string, () => void]> = []

  private _addListeners() {
    this.listeners.forEach(([type, callback]) => {
      document.addEventListener(type, callback)
    })
  }

  private _removeListeners() {
    this.listeners.forEach(([type, callback]) => {
      document.removeEventListener(type, callback)
    })
  }

  constructor() {
    const callback = () => {
      this.clients.forEach((c) => c(this.isFullscreen()))
    }

    this.listeners = [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'MSFullscreenChange',
    ].map((type) => [type, callback])
  }

  requestFullscreen(el: HTMLElement): void {
    const method = [
      'requestFullscreen',
      'webkitRequestFullscreen',
      'mozRequestFullScreen',
      'msRequestFullscreen',
    ].find((m) => m in el)
    if (method) {
      ;(el as any)[method]?.()
    }
  }

  isFullscreen(): boolean {
    const prop = [
      'fullscreenElement',
      'webkitFullscreenElement',
      'mozFullScreenElement',
      'msExitFullscreen',
    ].find((m) => m in document)
    if (prop) {
      return (document as any)[prop] !== null
    } else {
      return false
    }
  }

  exitFullscreen(): void {
    const method = [
      'exitFullscreen',
      'webkitExitFullscreen',
      'mozCancelFullScreen',
      'msExitFullscreen',
    ].find((m) => m in document)
    if (method) {
      ;(document as any)[method]?.()
    }
  }

  subscribe(callback: IFullscreenCallback): () => void {
    if (this.clients.size === 0) {
      this._addListeners()
    }
    this.clients.add(callback)
    return () => {
      this.clients.delete(callback)
      if (this.clients.size === 0) {
        this._removeListeners()
      }
    }
  }

  destroy(): void {
    this.clients.clear()
    this._removeListeners()
  }
}
