
import { name as appName } from '../../package.json'
import EmptyLayouts from './EmptyLayouts.vue'
import CameraGrid from './CameraGrid.vue'
import CreateLayout from './CreateLayout.vue'
import Mixins from './common/Mixins'
import SelectLayoutMenu from './common/SelectLayoutMenu.vue'
import Rename from './common/Rename.vue'
import { mapState, mapGetters } from 'vuex'
import { history, SearchParams } from '@netvision/lib-history'

export default Mixins.extend({
  name: 'LayoutsMain',
  components: {
    EmptyLayouts,
    CameraGrid,
    CreateLayout,
    SelectLayoutMenu,
    Rename,
  },
  data() {
    return {
      appName,
      userId: '',
      searchTitle: '',
      currentRoutePath: history.location.pathname,
      camerasLayouts: [] as CamerasLayout[],
      camerasLayoutsInMenu: [] as CamerasLayout[],
      camerasLayoutsInTabs: [] as CamerasLayout[],
      displayCreateCamerasLayout: false,
      cameras: {} as Record<string, Camera>,
      currentCamerasLayoutId: '',
      editMode: false,
      scrollPanel: {} as Element | null,
      isSpliting: false,
      count: null as null | number,
      unlisten: () => {},
    }
  },
  watch: {
    currentCamerasLayoutId(val: string) {
      let query = SearchParams.parse(history.location.search)
      if (val.length > 0) {
        query = {
          ...query,
          currentCamerasLayoutId: val,
        }
      } else {
        delete query.currentCamerasLayoutId
      }
      history.push({
        search: SearchParams.stringify({
          ...query,
        }),
      })
    },
  },
  computed: {
    ...mapState(['mainLoading']),
    ...mapGetters(['api', 'layouts']),
    isNewLayout(): Boolean {
      return !!this.currentCamerasLayout && this.currentCamerasLayout.cameras.length === 0
    },
    currentCamerasLayout: {
      get(): CamerasLayout | null {
        return this.camerasLayouts.find(({ id }) => id === this.currentCamerasLayoutId) || null
      },
      set(val: CamerasLayout) {
        const index = this.camerasLayouts.findIndex(({ id }) => id === this.currentCamerasLayoutId)
        if (index > -1) {
          const cameras: Camera[] = this.layouts[this.currentCamerasLayoutId]?.cameras || []
          val.cameras.forEach((cameraId, index) => {
            if (cameraId) {
              const targetCamera = cameras.find(({ id }) => id === cameraId)
              if (targetCamera) targetCamera.indexInLayout = index
            }
          })
          this.$store.commit('setLayout', [
            this.currentCamerasLayoutId,
            {
              ...this.layouts[this.currentCamerasLayoutId],
              cameras,
            },
          ])
          this.camerasLayouts.splice(index, 1, val)
        }
      },
    },
    ...mapState(['LAYOUT_TYPES']),
  },
  methods: {
    canI(scope: string, id: string | null = null): Boolean {
      const scopes = this.permissionScopes.get(id)
      return Array.isArray(scopes) ? scopes.includes(scope) : false
    },
    selectMenuLayout(layout: CamerasLayout) {
      this.camerasLayoutsInTabs.unshift(layout)
      this.camerasLayoutsInMenu = this.camerasLayoutsInMenu.filter((e) => e !== layout)
      this.camerasLayoutsInMenu.unshift(this.camerasLayoutsInTabs.pop() as CamerasLayout)
      this.camerasLayouts.unshift(this.camerasLayouts.pop() as CamerasLayout)
      const layoutIndexes = {} as Record<string, number>
      this.camerasLayoutsInTabs.forEach(({ id }, index) => {
        layoutIndexes[id] = index
      })
      localStorage.setItem(`${appName}:camerasLayoutsInMenu`, JSON.stringify(layoutIndexes))
      this.openLayout(layout)
    },
    onResize() {
      clearTimeout(this.inputTimer)
      // @ts-ignore
      this.inputTimer = setTimeout(this.splitLayouts, 300)
    },
    sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async splitLayouts() {
      if (!this.isSpliting && this.camerasLayouts.length > 0) {
        this.isSpliting = true
        const fullButtonsBar = this.$refs.fullButtonsBar as HTMLElement
        const camerasLayoutsInMenu = [...this.camerasLayouts]
        this.camerasLayoutsInTabs = []
        const currentButtonsBar = this.$refs.currentButtonsBar as HTMLElement
        do {
          this.camerasLayoutsInTabs.push(camerasLayoutsInMenu.shift() as CamerasLayout)
          await this.sleep(10)
        } while (
          fullButtonsBar.clientWidth > currentButtonsBar.clientWidth &&
          camerasLayoutsInMenu.length > 0
        )
        if (
          fullButtonsBar.clientWidth < currentButtonsBar.clientWidth &&
          this.camerasLayoutsInTabs.length > 0
        ) {
          camerasLayoutsInMenu.unshift(this.camerasLayoutsInTabs.pop() as CamerasLayout)
        }
        this.camerasLayoutsInMenu = camerasLayoutsInMenu
        this.isSpliting = false
      }
    },
    openLayout({ id }: CamerasLayout) {
      this.editMode = false
      this.currentCamerasLayoutId = id
    },
    createEmptyCamerasLayout(): Promise<void> {
      this.$store.commit('setValue', ['mainLoading', true])
      return this.api
        .createEntity({
          type: 'CamerasLayout',
          id: '',
          layoutType: Object.keys(this.LAYOUT_TYPES)[0],
          name: '',
          cameras: [],
        })
        .then(async () => {
          await this.fetchEntities()
          this.openLayout(this.camerasLayouts[0])
        })
        .catch(this.errorToast)
    },
    async fetchEntities() {
      this.$store.commit('setValue', ['mainLoading', true])
      return this.api
        .getEntitiesList({
          limiter: {
            type: 'CamerasLayout',
            limit: 1000,
            orderBy: '!type,createdAt',
          },
          filter: {
            count: true,
            q: [
              {
                key: 'owner',
                operator: '==',
                value: this.userId
              }
            ]
          },
        })
        .then(async ({ results, count }: { results: CamerasLayout[]; count: number }) => {
          this.count = count || 0
          const camerasLayouts = results
            .sort((a, b) =>
              a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : 0,
            )
            .reduce<CamerasLayout[]>((acc, next) => {
              next.name ? acc.push(next) : acc.unshift(next)
              return acc
            }, [])
          if (camerasLayouts.length > 0) {
            this.$store.commit('setValue', [
              'permissionScopes',
              new Map([
                ...this.permissionScopes,
                ...(await this.api.getPermissionsByIdsMap(camerasLayouts.map(({ id }) => id))),
              ]),
            ])
          }
          this.camerasLayouts = camerasLayouts
          await this.splitLayouts()
          if (
            this.camerasLayouts.length > 0 &&
            this.camerasLayouts.find(({ id }) => id === this.currentCamerasLayoutId) === undefined
          ) {
            this.openLayout(this.camerasLayouts[0])
          }
        })
        .catch(this.errorToast)
        .finally(this.$store.commit('setValue', ['mainLoading', false]))
    },
    parseCurrentQuery() {
      const query = SearchParams.parse(history.location.search)
      this.currentCamerasLayoutId =
        query.currentCamerasLayoutId !== undefined ? (query.currentCamerasLayoutId as string) : ''
    },
  },
  async mounted() {
    this.$store.commit('setValue', [
      'permissionScopes',
      this.permissionScopes.set(null, await this.api.getPermissions(['CreateCamerasLayout'])),
    ])
    try {
      const { userId } = await this.api.getUserInfo()
      this.userId = userId
    } catch (error) {
      console.error(error)
    }
    let search = JSON.stringify(SearchParams.parse(history.location.search))
    this.unlisten = history.listen(async (location) => {
      const locaSearch = JSON.stringify(SearchParams.parse(location.search))
      if (location.pathname === this.currentRoutePath && locaSearch !== search) {
        search = locaSearch
        this.parseCurrentQuery()
        if (this.currentCamerasLayoutId === '' && this.camerasLayouts.length > 0) {
          this.openLayout(this.camerasLayouts[0])
        }
      }
    })
    this.parseCurrentQuery()
    await this.fetchEntities()
  },
  destroyed() {
    this.unlisten()
  },
})
