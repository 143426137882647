
import { isEqual, isMatchWith } from 'lodash'
import Vue from 'vue'
export default Vue.extend({
  name: 'AdditionalCommands',
  inject: ['spaParent'],
  props: {
    cameras: Array as () => Camera[],
  },
  data() {
    return {
      showCommands: true,
      unmount: [] as any,
    }
  },
  computed: {
    matchedCamerasIds(): string[] {
      // @ts-ignore
      const blockOnMatch = this.spaParent?.props?.blockOnMatch
      return (
        blockOnMatch !== undefined
          ? this.cameras.filter((camera) => {
              return isMatchWith(camera, blockOnMatch, (objValue: unknown, srcValue: unknown) =>
                Array.isArray(srcValue) ? srcValue.includes(objValue) : isEqual(objValue, srcValue),
              )
            })
          : this.cameras
      ).map(({ id }) => id)
    },
  },
  watch: {
    cameras() {
      this.showCommands = false
      setTimeout(() => {
        this.showCommands = true
        setTimeout(() => {
          this.mountWidgets()
        })
      })
    },
  },
  destroyed() {
    this.unmount.forEach((e: any) => e())
  },
  mounted() {
    this.mountWidgets()
  },
  methods: {
    mountWidget(placeholder: HTMLElement, areas: any[]) {
      // @ts-ignore
      const spaParent = this.spaParent as SpaParent
      for (const area of areas) {
        this.unmount = [
          ...this.unmount,
          spaParent.mountChildren(
            placeholder,
            area.children.map((e: any) => {
              return {
                ...e,
                props: {
                  ...e.props,
                  cameraIds: this.matchedCamerasIds,
                },
              }
            }),
          ),
        ]
      }
    },
    mountWidgets() {
      const commandPlaceholder = this.$refs.commands as HTMLElement

      // @ts-ignore
      const commands = this.spaParent.areas.filter((e: any) => e.name === 'additionalCommands')

      this.mountWidget(commandPlaceholder, commands)
    },
  },
})
