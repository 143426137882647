
import { mapStateTyped } from '@/store'
import Vue from 'vue'

export default Vue.extend({
  name: 'LayoutStyleSelectSet',
  props: {
    currentLayoutType: String as () => LayoutType,
    totalCount: Number,
  },
  data() {
    return {
      localValue: this.currentLayoutType || '1x1',
      needMainCameraOnLayout: false,
    }
  },
  computed: {
    ...mapStateTyped(['LAYOUT_TYPES']),
    layoutStylesOptions(): Record<string, any>[] {
      return Object.entries(this.LAYOUT_TYPES)
        .filter(([_, { withMainCamera }]: any) =>
          this.needMainCameraOnLayout
            ? withMainCamera
            : [undefined, false].includes(withMainCamera),
        )
        .map(([key, value]: [string, any]) => {
          return { value: key, ...value }
        })
    },
    isThereCameraWithMain(): boolean {
      return Object.values(this.LAYOUT_TYPES).some(
        // @ts-ignore
        (val) => !!val?.withMainCamera,
      )
    },
  },
  watch: {
    localValue(val) {
      this.$emit('update:currentLayoutType', val)
    },
    currentLayoutType(val) {
      this.localValue = val
      const layoutStyle = this.LAYOUT_TYPES[this.currentLayoutType]
      this.needMainCameraOnLayout =
        'withMainCamera' in layoutStyle ? layoutStyle.withMainCamera : false
    },
    totalCount(val) {
      const localValueCount = this.LAYOUT_TYPES[this.localValue]?.count
      if (localValueCount < val) {
        this.localValue = this.minimalLayoutStyle()
      }
    },
  },
  mounted() {
    const layoutStyle = this.LAYOUT_TYPES[this.currentLayoutType]
    this.needMainCameraOnLayout =
      'withMainCamera' in layoutStyle ? layoutStyle.withMainCamera : false
  },
  methods: {
    layoutOptionDisabled({ count }: any): Boolean {
      return count < this.totalCount
    },
    minimalLayoutStyle(): LayoutType {
      const style = this.layoutStylesOptions.find(({ count }: any) => count >= this.totalCount)
      return style !== undefined ? style.value : ''
    },
  },
})
