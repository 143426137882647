
import Vue from 'vue'
import { debounce } from '@/utils'
import { mapGetters } from 'vuex'
import { IListPayload } from '@netvision/lib-api-repo'

export default Vue.extend({
  name: 'MultiRefField',
  props: {
    referenceIds: Array as () => string[],
    entityType: { type: String, default: 'ComplexObjectArea' },
  },
  data() {
    return {
      loadingStatus: 'init' as FetchingStatus,
      fetchedEntitiesMap: {} as Record<string, IEntity>,
      limit: 10,
      count: 0,
      infiniteScroll: true,
      filterValue: '',
      wrapperElement: null as HTMLElement | null,
      loadingLocale: `${this.$t('loading')}`,
      isMaximumAlreadyReached: false,
    }
  },
  computed: {
    ...mapGetters(['api']),
    localValue: {
      set(val: string[]): void {
        this.$emit('update:referenceIds', val)
      },
      get(): string[] {
        return this.referenceIds || []
      },
    },
    offset(): number {
      return this.refOptions.filter((id) =>
        this.getTitle(id).toLowerCase().includes(this.filterValue.toLowerCase()),
      ).length
    },
    refOptions(): string[] {
      return [
        ...this.localValue,
        ...Array.from(Object.keys(this.fetchedEntitiesMap)).filter(
          (id) => !this.localValue.includes(id),
        ),
        ...(this.loadingStatus === 'loading' ? [this.loadingLocale] : []),
      ]
    },
  },
  watch: {
    referenceIds: {
      handler(val) {
        val?.length && this.fetchValueEntities()
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchMoreEntities()
  },
  methods: {
    onFilter({ value }: { value: string }) {
      this.filterValue = value
      this.infiniteScroll = true
      value && debounce(this.fetchMoreEntities, 300)
    },
    addScrollListener() {
      setTimeout(() => {
        this.wrapperElement = document.querySelector('.p-multiselect-items-wrapper')
        this.wrapperElement && this.wrapperElement.addEventListener('scroll', this.onScroll)
      })
    },
    removeScrollListener(e: Event) {
      this.wrapperElement && this.wrapperElement.removeEventListener('scroll', this.onScroll)
    },
    onScroll() {
      if (this.wrapperElement) {
        const { offsetHeight, scrollHeight, scrollTop } = this.wrapperElement
        if (
          this.wrapperElement &&
          offsetHeight !== scrollHeight &&
          offsetHeight + scrollTop >= scrollHeight
        ) {
          !this.isMaximumAlreadyReached && this.fetchMoreEntities()
          this.isMaximumAlreadyReached = true
        } else {
          this.isMaximumAlreadyReached = false
        }
      }
    },
    getTitle(id: string): string {
      return this.loadingLocale !== id
        ? this.fetchedEntitiesMap?.[id]?.title || `${this.$t('unknownRef')}`
        : this.loadingLocale
    },
    async fetchValueEntities() {
      const entities = this.localValue
        .map((id: string) => ({
          id,
          type: this.entityType,
        }))
        .filter(({ id }) => !this.fetchedEntitiesMap[id])
      if (entities.length === 0) {
        return
      }
      this.loadingStatus = 'loading'
      try {
        const { results } = (await this.api.batchQuery({
          filter: {
            entities: this.localValue.map((id: string) => ({
              id,
              type: this.entityType,
            })),
          },
          limiter: {},
        })) as { results: IEntity[] }
        const resultsMap = {} as Record<string, IEntity>
        results.forEach((result) => {
          resultsMap[result.id] = result
        })
        this.fetchedEntitiesMap = { ...this.fetchedEntitiesMap, ...resultsMap }
        this.loadingStatus = 'complete'
      } catch (error) {
        this.loadingStatus = 'error'
      }
    },
    async fetchMoreEntities() {
      if (!this.infiniteScroll) {
        return Promise.resolve()
      }
      try {
        this.loadingStatus = 'loading'

        const options: IListPayload = {
          limiter: {
            type: this.entityType,
            orderBy: 'title',
            offset: this.offset,
            limit: this.limit,
          },
          filter: {
            count: true,
          },
        }

        if (this.filterValue.length > 0) {
          options.filter = {
            ...options.filter,
            q: [
              {
                key: 'title',
                value: this.filterValue,
                operator: '~=',
              },
            ],
          }
        }
        const { count, results } = await this.api.getEntitiesList(options)
        const resultsMap = {} as Record<string, IEntity>
        results.forEach((result: IEntity) => {
          resultsMap[result.id] = result
        })
        this.fetchedEntitiesMap = { ...this.fetchedEntitiesMap, ...resultsMap }
        this.count = count
        if (results.length === 0 || this.count === Object.keys(this.fetchedEntitiesMap).length) {
          this.infiniteScroll = false
        } else {
          this.infiniteScroll = true
        }
        this.loadingStatus = 'complete'
      } catch (error) {
        console.error(error)
        this.loadingStatus = 'error'
      }
    },
  },
})
