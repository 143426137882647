
import CardsView from './common/CardsView.vue'
import LayoutStyleSelectSet from './common/LayoutStyleSelectSet.vue'
import Mixins from './common/Mixins'
import MultiRefField from './common/MultiRefField.vue'
import { debounce } from '@/utils'
import { cloneDeep } from 'lodash'
import { Layout, mapGettersTyped, mapStateTyped } from '@/store'

export default Mixins.extend({
  name: 'CreateLayout',
  components: {
    CardsView,
    LayoutStyleSelectSet,
    MultiRefField,
  },
  props: {
    camerasLayout: Object as () => CamerasLayout,
  },
  data() {
    return {
      loading: false,
      displayDeleteLayout: false,
      unmount: {} as any,
      currentLayoutType: '1x1' as LayoutType | '',
      searchCamera: '',
      servicePath: this.camerasLayout.camerasParents || [],
    }
  },
  watch: {
    searchCamera(val: string) {
      const clearedVal = val.replace(/[^a-zA-Z0-9а-яА-Я-.,\s]/g, '')
      if (this.searchCamera.length === clearedVal.length) {
        this.requestDelay(true, () => {
          this.$store.commit('setLayout', [this.camerasLayout.id, { offset: 0 }])
          this.fetchCameras()
        })
      } else {
        this.searchCamera = clearedVal
      }
    },
    layouts: {
      handler(val, oldVal) {
        if (val[this.camerasLayout.id].offset !== oldVal[this.camerasLayout.id]?.offset) {
          debounce(this.fetchCameras, 300, 'cameras')
        }
        if (val[this.camerasLayout.id].limit !== oldVal[this.camerasLayout.id]?.limit) {
          debounce(this.fetchCameras, 300, 'cameras')
        }
      },
    },
    camerasLayout: {
      handler(val) {
        if (val !== null) {
          this.currentLayoutType = val.layoutType
          this.servicePath = val.camerasParents || []
          if (!(val.id in this.layouts)) {
            this.layouts[val.id] = new Layout()
            this.setLimit()
            debounce(this.fetchCameras, 300, 'cameras')
          }
        }
      },
    },
    servicePath() {
      debounce(this.fetchCameras, 300, 'cameras')
    },
  },
  computed: {
    ...mapStateTyped(['LAYOUT_TYPES', 'mainLoading', 'widgetProps']),
    ...mapGettersTyped(['api', 'layouts']),
    selected: {
      get(): string[] {
        return this.layouts[this.camerasLayout.id].selected
      },
      set(value: string[]) {
        this.$store.commit('setLayout', [this.camerasLayout.id, { selected: value }])
      },
    },
    offset: {
      get(): number {
        return this.layouts[this.camerasLayout.id].offset
      },
      set(value: number) {
        this.$store.commit('setLayout', [this.camerasLayout.id, { offset: value }])
      },
    },
    limit: {
      get(): number {
        return this.layouts[this.camerasLayout.id].limit
      },
      set(value: number) {
        this.$store.commit('setLayout', [this.camerasLayout.id, { limit: value }])
      },
    },
    count(): number {
      return this.layouts[this.camerasLayout.id].count
    },
    cameras(): Camera[] {
      return this.layouts[this.camerasLayout.id].cameras
    },
  },
  methods: {
    saveLayout() {
      if (
        this.layouts[this.camerasLayout.id].selected.length > 0 &&
        this.currentLayoutType &&
        this.LAYOUT_TYPES?.[this.currentLayoutType]
      ) {
        this.$store.commit('setValue', ['mainLoading', true])
        const restSpace = new Array(
          this.LAYOUT_TYPES[this.currentLayoutType].count -
            this.layouts[this.camerasLayout.id].selected.length,
        ).fill('')
        const options = {
          type: 'CamerasLayout',
          id: this.camerasLayout.id,
          layoutType: this.currentLayoutType,
          cameras: [...this.layouts[this.camerasLayout.id].selected, ...restSpace],
        }
        this.api
          .updateEntity({
            type: 'CamerasLayout',
            id: this.camerasLayout.id,
            layoutType: this.currentLayoutType,
            cameras: this.layouts[this.camerasLayout.id].selected,
          })
          .then(() => {
            this.$emit('update:camerasLayout', {
              ...this.camerasLayout,
              ...options,
            })
          })
          .catch(this.errorToast)
      }
    },
    async fetchCameras() {
      if (!this.api.cubeGetEntities) {
        throw new Error("cubeGetEntities method doesn't exist in api")
      }
      if (!this.widgetProps?.cubeListQuery) {
        throw new Error("cubeListQuery property doesn't provide in spa props")
      }

      this.$store.commit('setValue', ['mainLoading', true])
      const queryConfig = cloneDeep(this.widgetProps.cubeListQuery)

      if (this.searchCamera.length) {
        queryConfig.filters.push({
          member: 'Objects.title',
          operator: 'contains',
          values: [
            this.searchCamera,
            this.searchCamera.toUpperCase(),
            this.searchCamera.toLowerCase(),
            this.searchCamera[0].toUpperCase() + this.searchCamera.substring(1).toLowerCase(),
          ],
        })
      }
      if (this.servicePath.length) {
        queryConfig.filters.push({
          member: 'Objects.servicePath',
          operator: 'equals',
          values: [...this.servicePath],
        })
      }

      try {
        const getCameras = async () => {
          const { results } = await this.api.cubeGetEntities({
            ...queryConfig,
            offset: this.layouts[this.camerasLayout.id].offset,
            limit: this.layouts[this.camerasLayout.id].limit,
          })
          this.$store.commit('setLayout', [this.camerasLayout.id, { cameras: results }])
        }

        const getCount = async () => {
          this.$store.commit('setLayout', [
            this.camerasLayout.id,
            {
              count: await this.api.cubeGetQuantity({
                filters: queryConfig.filters,
                measures: ['Objects.count'],
              }),
            },
          ])
        }
        await Promise.all([getCameras, getCount].map((fn) => fn()))
      } catch (error) {
        this.errorToast(error)
      } finally {
        this.$store.commit('setValue', ['mainLoading', false])
      }
    },
    setLimit() {
      const containerWidth: number = (this.$refs.container as HTMLElement).clientWidth - 60
      switch (true) {
        case containerWidth - 1480 < 0:
          this.$store.commit('setLayout', [this.camerasLayout.id, { limit: 6 }])
          break
        case containerWidth - 1540 < 0:
          this.$store.commit('setLayout', [this.camerasLayout.id, { limit: 8 }])
          break
        default:
          this.$store.commit('setLayout', [this.camerasLayout.id, { limit: 10 }])
      }
    },
    deleteLayout(): Promise<void> {
      return this.api
        .deleteEntity({
          type: 'CamerasLayout',
          id: this.camerasLayout.id,
        })
        .then(() => {
          this.$emit('closeGrid')
          this.displayDeleteLayout = false
        })
        .catch(this.errorToast)
    },
  },
  created() {
    if (!(this.camerasLayout.id in this.layouts)) {
      this.$store.commit('setLayout', [this.camerasLayout.id])
    }
  },
  mounted() {
    if (!this.layouts[this.camerasLayout.id].cameras.length) {
      this.setLimit()
    }
  },
})
