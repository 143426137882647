
import Vue from 'vue'
import { name as appName } from '@/../package.json'
import { mapMutationsTyped, mapStateTyped } from '@/store'
import { capitalize } from '@/utils'
// write function that meoize the stream type

export default Vue.extend({
  name: 'SelectStreamMenu',
  props: {
    camerasLayoutId: String,
  },
  data() {
    return {
      appName,
    }
  },
  computed: {
    ...mapStateTyped(['widgetProps', 'userId', 'layoutStore']),
    currentStreamType(): string | undefined {
      return this.layoutStore?.[this.camerasLayoutId]?.streamType
    },
    streamTypes(): WidgetProps['streamTypes'] {
      return this?.widgetProps?.streamTypes || []
    },
    currentStreamTitle(): string | undefined {
      const currentStreamType = this.streamTypes.find(
        ({ streamType }) => streamType === this.currentStreamType,
      )
      return currentStreamType?.title
    },
  },
  methods: {
    ...mapMutationsTyped(['setValue', 'setLayoutStream', 'clearLayoutStore']),
    menuToggle(ref: string, event?: Event) {
      let menu = this.$refs[ref] as Vue & {
        toggle: (event?: Event) => {}
      }
      menu = Array.isArray(menu) ? menu[0] : menu
      if (menu !== undefined) {
        menu!.toggle(event)
      }
    },
    te(str: string, def: string) {
      return this.$te(str) ? this.$t(str) : capitalize(def)
    },
    switchStreamType(streamType?: string) {
      if (streamType) {
        this.setLayoutStream([this.camerasLayoutId, streamType])
      } else {
        this.clearLayoutStore(this.camerasLayoutId)
      }
      this.$eventBus.$emit('switchStreamType', [streamType])
    },
  },
})
